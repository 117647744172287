<template>
  <div class="confirm-send-mail-dialog">
    <h2
      class="text-center text-body-1 font-weight-light confirm-send-mail-dialog__subtitle"
    >
      {{ $trans("reset_password_success_text") }}
    </h2>
    <div class="mb-4 mt-4">
      <div class="confirm-send-mail-dialog__content">
        <v-btn color="primary" height="56px" @click="handleClose">
          {{ $trans("reset_password_success_button") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmPasswordChangedDialog",
  methods: {
    handleClose() {
      this.$emit("close");
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style>
.confirm-send-mail-dialog__subtitle {
  padding: 0 10px;
}
.confirm-send-mail-dialog__content {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

@media (min-width: 768px) {
  .confirm-send-mail-dialog__subtitle {
    margin-bottom: 10px;
    padding: 0 65px;
  }

  .confirm-send-mail-dialog__content {
    padding: 10px 65px;
  }
}
</style>
